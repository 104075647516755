import React from 'react';
import Header from '../components/Header';
import Banner from '../assets/tru13.png';
import { FaDotCircle } from 'react-icons/fa';
import Footer from '../components/Footer';

const Services = () => {
  return (
    <div className=''>
      <div className='flex flex-col relative w-screen h-[35rem]'>
            <span className="absolute w-full bg-[#180d06] h-full"><img className='w-full h-full opacity-50' src={Banner} alt='' ></img></span>
            <span className='z-20'><Header /></span>
            <span className='flex flex-col z-10 lg:px-28 px-6 lg:gap-8 gap-4 lg:mt-20 mt-28'>
                <span className='flex lg:px-6 px-3 py-1 bg-[#ffffff]/30 items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='lg:text-3xl text-2xl font-semibold text-[#ffffff] tracking-wider'>Our Services</span>
                </span>
                <span className='flex flex-col gap-1 text-[#ffffff]'>
                    <span className='lg:text-6xl text-4xl font-semibold tracking-wide'>Services List</span>
                </span>
            </span>
      </div>
      <div className='flex flex-col lg:pt-32 pt-16 lg:gap-24 gap-12'>
        <span className='flex flex-col lg:gap-7 gap-3 lg:px-20 px-6'>
          <span className='lg:text-3xl text-2xl uppercase font-medium opacity-80'>Areas of activity</span>
          <span className='flex flex-col lg:gap-12 gap-6'>
            <span className='flex lg:flex-row flex-col gap-6 lg:gap-0 justify-between'>
              <span className='flex flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru6.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Gas Industries</span>
                <span className='flex flex-col gap-4 lg:pt-6 pt-3'>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Gas Processing</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base  font-light text-[#ee803c]'>LNG Plants</span>
                    </span>
                  </span>
                  <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base  font-light text-[#ee803c]'>CNG Mother & Daughter stations</span>
                  </span>
                </span>
              </span>
              <span className='flex flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru14.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Upstream Oil Sector</span>
                <span className='flex flex-col gap-4 lg:pt-6 pt-3'>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Exploitation</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Production</span>
                    </span>
                  </span>
                  <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>(Onshore, Shallow water & Deep water)</span>
                  </span>
                </span>
              </span>
            </span>
            <span className='flex lg:flex-row flex-col gap-6 lg:gap-0 justify-between'>
              <span className='flex flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru15.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Downstream Oil Sector</span>
                <span className='flex flex-col gap-4 pt-6'>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Trading & Supply</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Distribution</span>
                    </span>
                  </span>
                  <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Tank farms and Terminals</span>
                  </span>
                </span>
              </span>
              <span className='flex flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru5.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Midstream Oil Sector</span>
                <span className='flex flex-col gap-4 pt-6'>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Pipelines</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Processing Plants</span>
                    </span>
                  </span>
                  <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                        <FaDotCircle className='w-4 text-[#ee803c]'/>
                        <span className='lg:text-lg text-base font-light text-[#ee803c]'>Petrochemical Refineries</span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
        <div className='flex flex-col lg:gap-24 gap-12 lg:py-24 py-12 bg-gray-50  lg:px-20 px-6'>
          <span className='flex lg:flex-row flex-col gap-12 lg:gap-0 justify-between'>
            <span className='flex flex-col gap-7'>
              <span className='text-3xl uppercase font-medium opacity-80'>Capabilities</span>
              <span className='flex gap-6 flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru22.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='flex flex-col gap-4'>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg text-base tracking-tight font-light text-[#ee803c]'>Full Suite of Inspection Technology Solutions</span>
                    </span>
                  </span>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Wellhead Systems (Surface and Subsea Controls)</span>
                    </span>
                  </span>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Flow Station and Pipeline Metering Services</span>
                    </span>
                  </span>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Turnkey Instrumentation, Control & Electrical Projects</span>
                    </span>
                  </span>
                  <span className='flex gap-2'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg text-base  font-light text-[#ee803c]'>Procurement, Construction, Installation & Commissioning Services</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span className='flex flex-col gap-7'>
              <span className='text-3xl uppercase font-medium opacity-80'>Operations and maintenace</span>
              <span className='flex gap-6 flex-col'>
                <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                  <span className='bg-[url("../assets/tru16.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                </span>
                <span className='flex gap-4 flex-col'>
                  <span className='flex lg:flex-row flex-col lg:gap-2 gap-4'>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg textbase  font-light text-[#ee803c]'>Plant Maintenance</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ee803c]'/>
                      <span className='lg:text-lg textbase  font-light text-[#ee803c]'>Oil field Management & Services </span>
                    </span>
                  </span>
                  <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='lg:text-lg textbase  font-light text-[#ee803c]'>Fire and Systems Personnel Training & Manpower</span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span className='flex flex-col gap-7'>
            <span className='text-3xl uppercase font-medium opacity-80'>Engineering services</span>
            <span className='flex flex-col gap-12'>
              <span className='flex lg:flex-row flex-col gap-12 lg:gap-0 justify-between'>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru23.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>
                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Electrical Engineering</span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Cable sizing & selection</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Single line diagrams</span>
                      </span>
                    </span>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>MCC panel designs</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Power / Lighting / Grounding plans</span>
                      </span>
                    </span>
                    <span className='flex gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Electrical component specifications</span>
                      </span>
                      {/* <span className='flex px-6 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='text-lg  font-light text-[#ee803c]'>Power / Lighting / Grounding plans</span>
                      </span> */}
                    </span>
                  </span>
                </span>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru24.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>
                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Process Engineering</span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Process Studies</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Plant Optimization & Debottlenecking</span>
                      </span>
                    </span>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Hydraulic Analysis</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Process Modelling & Simulation</span>
                      </span>
                    </span>
                    <span className='flex gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Feasibility, Expansion & Revamp Studies</span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span className='flex lg:flex-row flex-col gap-12 lg:gap-0 justify-between'>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru25.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>
                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Civil/Structural Engineering </span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Site Plan</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Steel Structure Design</span>
                      </span>
                    </span>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Foundation design</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Pipe racks and supports</span>
                      </span>
                    </span>
                  </span>
                </span>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru26.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>

                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Mechanical & Piping Engineering </span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Rotating Equipment Design </span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Process Piping Design</span>
                      </span>
                    </span>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Pipe stress Analysis</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Equipment layouts & arrangments</span>
                      </span>
                    </span>
                    <span className='flex gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Storage Tanks & Pressure Vessel design</span>
                      </span>

                    </span>
                  </span>
                </span>
              </span>
              <span className='flex lg:flex-row flex-col gap-12 lg:gap-0 justify-between'>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru28.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>
                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Loss Prevention Engineering</span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>HAZID, HAZOP, LOPA</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Safety Philosophies</span>
                      </span>
                    </span>
                    <span className='flex lg:flex-row flex-col gap-4 lg:gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c] tracking-tight'>Drop Object Study</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c] tracking-tight'>Escape Evacuation & Rescue Analysis</span>
                      </span>
                    </span>
                  </span>
                </span>
                <span className='flex flex-col'>
                  <span className='h-[15rem] lg:w-[36rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl  text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru29.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                  </span>
                  <span className='text-2xl font-medium opacity-80 lg:pt-6 pt-3'>Instrumentation & Control Engineering </span>
                  <span className='flex flex-col gap-4 pt-6'>
                    <span className='flex gap-2'>
                      <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Control Systems designs</span>
                      </span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>PLC/HMI configuration & programming</span>
                    </span>
                    <span className='flex lg:px-6 px-3 py-1 opacity-90 bg-[#f4ede9] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ee803c]'/>
                          <span className='lg:text-lg textbase font-light text-[#ee803c]'>Process monitoring & measurement systems design</span>
                      </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div className='flex lg:flex-row flex-col-reverse gap-6 lg:gap-0 lg:py-8 py-4 lg:px-20 px-6 justify-between items-center'>
          <span className='rounded-2xl bg-[#000000]/30 lg:w-[29rem] w-[22rem] h-[20rem] lg:h-[27rem] bg-[url("../assets/tru30.png")] bg-cover bg-center'></span>
          <span className='flex flex-col gap-2'>
              <span className='flex flex-col gap-1 text-[#000000]/60'>
                  <span className='lg:text-4xl text-3xl font-bold tracking-wide'>Consultancy</span>
              </span>
              <span className='text-[#000000]/60 text-lg font-light lg:w-[42rem]'>Consultancy is fundamental to our business services since it allows us to help our clients from the initiation of the business idea to the end of the project process, ensuring that the strategy fits well within the broader business context and framework. As an agile, focused and flexible partner we adopt a value-driven strategy to define and articulate project objectives, before identifying the most effective means to deliver. Regardless of scale or complexity of a project, we explore and refine our systems and processes from inception to completion</span> 
              <span className='flex flex-col gap-3'>
                  <span className='flex gap-3 lg:flex-row flex-col'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Schedule Development</span>
                      </span>
                      <span className='lg:flex hidden lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Project Coordination & Interface Management</span>
                      </span>
                  </span>
                  <span className='flex lg:hidden lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ffffff]'/>
                      <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Project Coordination</span>
                  </span>
                  <span className='flex lg:hidden lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Interface Management</span>
                  </span>
                  <span className='flex lg:flex-row flex-col gap-3'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Tenedring and Procurement support</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Custody transfer Metering system</span>
                      </span>
                  </span>
                  <span className='flex lg:flex-row flex-col gap-3'> 
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 '>Project Execution Plan & Methodology </span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Quality Assurance Management</span>
                      </span>
                  </span>
              </span>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services;