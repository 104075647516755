import React from 'react';
import Header from '../components/Header';
import Banner from '../assets/HomeBanner2.png';
import { FaDotCircle } from 'react-icons/fa';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Icon1 from '../assets/truicon1.png';
import Icon2 from '../assets/truicon2.png';
import Icon3 from '../assets/truicon3.png';

const Home = () => {
  return (
    <div className='flex flex-col gap-6 w-screen'>
        <div className='flex flex-col relative w-screen h-[45rem]'>
            <span className="absolute w-full bg-[#180d06] h-full"><img className='w-full h-full opacity-50' src={Banner} alt='' ></img></span>
            <span className='z-20'><Header /></span>
            <span className='flex flex-col z-10 lg:px-28 px-6 gap-6 lg:mt-9 mt-28'>
                <span className='flex lg:px-6 px-3 py-1 bg-[#ffffff]/30 items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='lg:text-3xl text-2xl font-semibold text-[#ffffff] tracking-wider'>We are Tru.</span>
                </span>
                <span className='flex flex-col gap-1 text-[#ffffff]'>
                    <span className='lg:text-6xl text-4xl font-semibold lg:tracking-wide'>Transforming the</span>
                    <span className='lg:text-6xl text-4xl font-semibold lg:tracking-wide'>future of oil and gas</span>
                </span>
                <span className='text-[#ffffff] text-xl font-light lg:w-[28rem]'>We provide a diverse array of services across various sectors, such as oil and gas, chemicals, and power industries, with a footprint extending across the African region.</span>
                <span className='flex lg:gap-8 gap-3 text-[#ffffff]'>
                    <Link to='/about-us'>
                        <span className='flex lg:px-10 px-3 py-2 rounded-[2rem] w-fit gap-2 items-center bg-[#ee803c] hover:bg-[#ffffff]/30 transition-all duration-500 ease-in-out transform'>
                            <FaDotCircle className='w-4'/>
                            <span className='lg:text-xl text-lg font-light'>Get Started</span>
                        </span>
                    </Link>

                    <Link to='/our-services'>
                        <span className='flex lg:px-10 px-3 py-2 rounded-[2rem] w-fit border gap-2 items-center hover:bg-[#ee803c] transition-all duration-500 ease-in-out transform text-[#ee803c] hover:text-[#ffffff]'>
                            <FaDotCircle className='w-4'/>
                            <span className='lg:text-xl text-lg font-light'>Our Services</span>
                        </span>
                    </Link>
                    
                </span>
            </span>
        </div>
        <div className='flex lg:pt-32 pt-16 justify-center items-center'>
            <span className='flex lg:flex-row flex-col gap-4 justify-center'>
                <span className='flex flex-col gap-3 p-4 rounded-xl items-center border-2 border-[#000000]/30 '>
                    <span className='rounded-full w-24 h-24 bg-[#ee803c] flex justify-center items-center'>
                        <img src={Icon1} alt="" className='w-12 h-12'/>
                    </span>
                    <span className='flex flex-col w-[18rem] items-center'>
                        <span className='text-2xl font-semibold opacity-80'>Our Vision</span>
                    </span>
                    <span className='text-lg font-light lg:w-[22rem] w-[18rem] text-center opacity-70'>To navigate the complexities of the energy sector to unearth valuable resources, optimise production, and provide innovative engineering solutions with a keen focus on professionalism, integrity, safety, quality and cost.</span>
                </span>
                <span className='flex flex-col gap-3 p-4 rounded-xl items-center border-2 border-[#000000]/30'>
                    <span className='rounded-full w-24 h-24 bg-[#ee803c] flex justify-center items-center'>
                        <img src={Icon2} alt="" className='w-12 h-12'/>
                    </span>
                    <span className='flex flex-col w-[18rem] items-center'>
                        <span className='text-2xl font-semibold opacity-80'>Our Mission</span>
                    </span>
                    <span className='text-lg font-light lg:w-[22rem] w-[18rem] text-center opacity-70'>To be a beacon of excellence in the energy industry, powered by our dynamic team of engineers and technical pioneers.</span>
                </span>
                <span className='flex flex-col gap-3 p-4 rounded-xl items-center border-2 border-[#000000]/30'>
                    <span className='rounded-full w-24 h-24 bg-[#ee803c] flex justify-center items-center'>
                        <img src={Icon3} alt="" className='w-12 h-12'/>
                    </span>
                    <span className='flex flex-col w-[18rem] items-center'>
                        <span className='text-2xl font-semibold opacity-80'>Core Values</span>
                    </span>
                    <span className='text-lg font-light lg:w-[22rem] w-[18rem] text-center opacity-70'>Tru Energy is driven by and dedicated to sustaining the values of honesty, respect, loyalty, efficiency, and transparency in all that we do. Our employee's dedication to our ideals and high standards of professionalism are the two most important aspects of Tru energy's continued success.</span>
                </span>
            </span>
        </div>
        <div className='flex lg:flex-row flex-col gap-6 lg:gap-0 lg:pt-32 pt-16 pb-16 lg:px-28 px-6 lg:justify-between items-center'>
            <span className='flex flex-col gap-6 '>
                <span className='flex px-6 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ffffff]'/>
                    <span className='lg:text-3xl text-2xl font-semibold text-[#ffffff]/90 tracking-wider'>Company Overview</span>
                </span>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='lg:text-4xl text-3xl font-bold tracking-wide'>Unswerving Dedication</span>
                    <span className='lg:text-4xl text-3xl font-bold tracking-wide'>to our principles</span>
                </span>
                <span className='text-[#000000]/60 text-xl font-light lg:w-[28rem]'>We provide a diverse array of services across various sectors, such as oil and gas, chemicals, and power industries, with a footprint extending across the African region.</span>
                <span className='flex gap-8 text-[#ffffff]'>
                    <Link to='/about-us'>
                        <span className='flex lg:px-10 px-3 py-2 rounded-[2rem] w-fit border gap-2 items-center bg-[#f4ede9] hover:bg-[#ee803c] transition-all duration-500 ease-in-out transform text-[#ee803c] hover:text-[#ffffff]'>
                            <FaDotCircle className='w-4'/>
                            <span className='lg:text-xl text-lg font-light'>Get Started</span>
                        </span>
                    </Link>
                </span>
            </span>
            <span className='rounded-2xl bg-[url("../assets/tru18.png")] bg-center bg-cover lg:w-[35rem] lg:h-[35rem] w-[22rem] h-[20rem]'></span>
        </div>
        <div className='flex flex-col lg:px-24 px-6 lg:gap-16 gap-8 bg-[#f4ede9] lg:py-28 py-12 h-fit'>
            <span className='flex justify-between items-center'>
                <span className='lg:text-5xl text-3xl font-bold text-[#000000]/80'>Our Services</span>
                <Link to='/our-services'>
                    <span className='flex lg:px-10 px-3 lg:py-3 py-1 rounded-[2rem] w-fit gap-2 items-center border-[#000000]/20 hover:bg-[#ee803c] transition-all duration-500 ease-in-out transform text-[#ee803c] hover:text-[#ffffff] border'>
                        <FaDotCircle className='w-4'/>
                        <span className='lg:text-xl text-lg font-light'>View all</span>
                    </span>
                </Link>
            </span>
            <span className='flex lg:flex-row flex-col lg:gap-4 gap-6 justify-center items-center'>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru8.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Upstream Oil Sector</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru7.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Wellhead Systems</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru9.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Gas Industries</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
            </span>
        </div>
        <div className='flex lg:flex-row flex-col-reverse lg:px-20 px-6 gap-4 lg:gap-0 justify-between items-center lg:py-12 py-6'>
            <span className='rounded-2xl bg-[#000000]/30 lg:w-[31rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru27.png")] bg-cover bg-center'></span>
            <span className='flex flex-col lg:gap-6 gap-3'>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='lg:text-4xl text-3xl font-bold tracking-wide'>Enviroment, Health & Safety</span>
                </span>
                <span className='text-[#000000]/60 lg:text-xl text-lg font-light lg:w-[42rem]'>Our dedication to safety extends beyond our immediate operations; it is interwoven with our commitment to sustainable practices and environmental stewardship. By fostering a culture of safety and fostering community engagement, we not only fulfill our obligations as responsible corporate citizens but also contribute positively to the well-being of the environments we operate within.</span>
                <span className='text-[#000000]/60 lg:text-xl text-lg font-light lg:w-[42rem]'>We hold safety as a paramount value, conscientiously safeguarding the well-being of our workforce and the communities in which we operate. In our unwavering pursuit of safety, we establish and uphold collaborative relationships with our stakeholders, nurturing open lines of communication and partnerships that ensure the alignment of safety protocols with the collective interests of all concerned parties.</span>
            </span>
        </div>
        <Footer/>
    </div>
  )
}

export default Home;