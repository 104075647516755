import {React, useState} from 'react';
import { FaDotCircle } from 'react-icons/fa';
import Logo from '../assets/tru.png';
import { Link } from 'react-router-dom';
import {HiOutlineMenu} from 'react-icons/hi';
import {CgClose} from 'react-icons/cg';


const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

  return (
    <div className='flex py-8 lg:px-28 px-6'>
        <div className='flex justify-between items-center w-screen text-[#ffffff]'>
          <Link to='/'>
            <div className='flex flex-col items-center'>
              <img src={Logo} alt="" className='w-20 h-12'/>
              <span className='font-bold text-base tracking-wider text-[#ee803c]'>Energy</span>
            </div>
          </Link>
          <div className='items-center px-2 gap-20 font-normal text-xl lg:flex hidden'>
              <Link to='/'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Home</span></Link>
              <Link to='/about-us'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>About Us</span></Link>
              <Link to='/our-services'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Our Services</span></Link>
              <Link to='/business-areas'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Business Areas</span></Link>
          </div>
          <span className='rounded p-1 h-fit flex lg:hidden items-center justify-center bg-[#794B02]' onClick={toggleMenu}>
          {isOpen ? <CgClose className='text-2xl text-white'/> : <HiOutlineMenu className='text-2xl text-white'/>} 
        </span>
        {
            isOpen && (
                <div className='absolute top-0 left-0 bg-white text-[#DD8A03] gap-10 rounded-b-md py-10 px-2 w-[80%] h-max z-50 flex flex-col text-start text-lg font-semibold'>
                  <div className='flex flex-col bg-white px-4'>
                    <img src={Logo} alt="" className='w-20 h-12'/>
                    <span className='font-bold text-base tracking-wider text-[#ee803c]'>Energy</span>
                  </div>
                  <div className='flex flex-col px-2 gap-4 justify-center items-cent'>
                    <Link to='/'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Home</span></Link>
                    <Link to='/about-us'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>About Us</span></Link>
                    <Link to='/our-services'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Our Services</span></Link>
                    <Link to='/business-areas'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Business Areas</span></Link>
                    <Link to='/contact-us'><span className='hover:text-[#ee803c] transition-all duration-200 ease-in-out transform'>Get In Touch</span></Link>
                  </div>
                </div>
            )
        }
          <div className='lg:flex hidden items-center'>
            <Link to='/contact-us'><span className='flex px-10 py-3 rounded-[2rem] w-fit border gap-2 items-center text-[#ee803c]'>
                <FaDotCircle className='w-4'/>
                <span>Get in Touch</span>
              </span></Link>
          </div>
        </div>
    </div>
    
  )
}

export default Header;