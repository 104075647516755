import React from 'react';
import { FaDotCircle } from 'react-icons/fa';
import Logo from '../assets/tru.png'

const Footer = () => {
  return (
    <div className='flex flex-col'>
        <div className='flex flex-col lg:px-24 px-6 lg:pt-24 pt-12 pb-8'>
            <img src={Logo} alt="" className='w-28 h-20'/>
        </div>
        <span className='flex lg:px-24 px-6 lg:pb-16 pb-8 border-b lg:gap-10 gap-5'>
            <span className='flex lg:flex-row flex-col lg:gap-16 gap-8'>
                <span className='flex flex-col lg:gap-8 gap-4'>
                    <span className='flex flex-col gap-3 text-[#000000]/80'>
                        <span className='lg:text-5xl text-3xl font-semibold tracking-wider'>Transforming the</span>
                        <span className='lg:text-5xl text-3xl font-semibold tracking-wider'>future of oil and gas</span>
                    </span>
                    <span className='flex flex-wrap lg:gap-8 gap-4 items-center lg:text-lg text-sm font-semibold lg:font-light text-[#000000]/60'>
                        <span>Home</span>
                        <span>About Us</span>
                        <span>Our Services</span>
                        <span>Business Areas</span>
                    </span>
                </span>
                <span className='flex flex-col gap-2'>
                    <span className='flex lg:px-9 px-6 py-2 rounded-[2rem] w-fit gap-2 items-center bg-[#ee803c] text-[#ffffff]/90 mb-4'>
                        <FaDotCircle className='w-4'/>
                        <span className='text-2xl font-light tracking-wider'>Lagos</span>
                    </span>
                    <span className='flex flex-col'>
                        <span className='text-xl font-medium opacity-80'>223 A&B, Etim Inyang </span>
                        <span className='text-xl font-medium opacity-80'>Crescent Victoria Island Lagos</span>
                    </span>
                    <span className='text-xl font-medium opacity-80'>+234 805 337 4469</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <span className='flex lg:px-9 px-6 py-2 rounded-[2rem] w-fit gap-2 items-center bg-[#ee803c] text-[#ffffff]/90 mb-4'>
                        <FaDotCircle className='w-4'/>
                        <span className='text-2xl font-light tracking-wider'>Abuja</span>
                    </span>
                    <span className='flex flex-col'>
                        <span className='text-xl font-medium opacity-80'>4 Rhine Street, Maitama,</span>
                        <span className='text-xl font-medium opacity-80'>FCT, Abuja</span>
                    </span>
                    <span className='text-xl font-medium opacity-80'>+234 803 825 6817</span>
                </span>
            </span>
        </span>
        <span className='lg:py-16 py-10 lg:px-24 px-6 flex text-[#000000]/60'>
            <span className='lg:text-xl text-base font-light'>Copyright © Tru Energy All rights reserved.</span>
        </span>
    </div>
  )
}

export default Footer;