import React from 'react';
import { FaDotCircle } from 'react-icons/fa';
import Header from '../components/Header';
import Banner from '../assets/tru11.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className='flex flex-col gap-6'>
        <div className='flex flex-col relative w-screen h-[40rem]'>
            <span className="absolute w-full bg-[#180d06] h-full"><img className='w-full h-full opacity-50' src={Banner} alt='' ></img></span>
            <span className='z-20'><Header /></span>
            <span className='flex flex-col z-10 lg:px-28 px-6 gap-6 lg:mt-12 mt-20'>
                <span className='flex lg:px-6 px-3 py-1 bg-[#ffffff]/30 items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='lg:text-3xl text-2xl font-semibold text-[#ffffff] tracking-wider'>About</span>
                </span>
                <span className='flex flex-col gap-1 text-[#ffffff]'>
                    <span className='lg:text-6xl text-4xl font-semibold tracking-wide'>Our Company</span>
                </span>
                <span className='text-[#ffffff] text-xl font-light lg:w-[35rem]'>We have become a preferred partner to leading biofuel refiners, major oil companies, blenders, retailers, and other commodity companies.</span>
                
            </span>
        </div>
        <div className='flex lg:flex-row flex-col py-12 px-6  lg:px-28 gap-8 lg:gap-0 justify-between items-center'>
            <span className='flex flex-col lg:gap-6 gap-3'>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='lg:text-4xl text-2xl font-bold tracking-wide'>Transforming the</span>
                    <span className='lg:text-4xl text-2xl font-bold tracking-wide'>future of oil and gas</span>
                </span>
                <span className='text-[#000000]/60 text-xl font-light lg:w-[32rem]'>TRU Energy is an indigenous energy company with highly experienced engineers and technical consultants whose collective experience spans over 60 years. Our team's wealth of knowledge enables us to provide a diverse array of services across various sectors, such as oil and gas, chemicals, and Dower industries, with a footprint extending across the African region.</span>
            </span>
            <span className='rounded-2xl lg:w-[35rem] lg:h-[30rem] w-[22rem] h-[20rem] bg-[url("../assets/tru17.png")] bg-cover bg-center'></span>
        </div>
        <div className='flex flex-col bg-[#f4ede9] lg:py-24 py-12 gap-3 lg:gap-0'>
            <div className='flex lg:flex-row flex-col lg:px-28 px-6 gap-6 lg:gap-0 justify-between items-center'>
                <span className='rounded-2xl lg:w-[35rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru4.png")] bg-cover bg-center'></span>
                <span className='flex flex-col gap-6 '>
                    <span className='flex flex-col gap-1 text-[#000000]/60'>
                        <span className='lg:text-4xl text-2xl font-bold tracking-wide'>Unswerving Dedication</span>
                        <span className='lg:text-4xl text-2xl font-bold tracking-wide'>to our principles</span>
                    </span>
                    <span className='text-[#000000]/60 text-xl font-light lg:w-[32rem]'>We at Tru Energy take great pride in our unswerving dedication to the principles of safety, environmental consciousness, social responsibility, and active community engagement, as well as our dedication to providing the highest standard of operational excellence in all of our oil and gas exploration, refining, infrastructure development, and oilfield management services.</span>
                </span>
            </div>
            <div className='flex lg:flex-row flex-col lg:px-28 px-6 gap-6 lg:gap-0 justify-between items-center'>
                <span className='flex flex-col gap-6'>
                    <span className='text-[#000000]/60 text-xl font-light lg:w-[32rem]'>As an energy company, we acknowledge the evolving landscape of the energy sector and the compelling shift towards sustainability and a low-carbon future. As custodians of innovation, we drive relentlessly towards pioneering breakthroughs and harnessing cutting-edge technology This commitment enables us to harmonise our efforts, ensuring the delivery of sustainable energy solutions that echo our resolute devotion to a greener tomorrow.</span>
                    <span className='flex flex-col lg:gap-3 gap-1'>
                        <span className='flex lg:gap-5 gap-2'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-xl text-base font-light text-[#ffffff]/90 lg:tracking-wider'>5 Countries</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-xl text-base font-light text-[#ffffff]/90 lg:tracking-wider'>Highly effective</span>
                            </span>
                        </span>
                    </span>
                    <span className='flex flex-col lg:gap-3 gap-2'>
                        <span className='flex lg:gap-5 gap-2'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-xl text-base font-light text-[#ffffff]/90 lg:tracking-wider'>Cost effective</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-xl text-base font-light text-[#ffffff]/90 lg:tracking-wider'>4.9/5 from clients</span>
                            </span>
                        </span>
                    </span>
                </span>
                <span className='rounded-2xl lg:w-[35rem] lg:h-[30rem] w-[22rem] h-[20rem] bg-[url("../assets/tru5.png")] bg-cover bg-center'></span>
            </div>
        </div>
        <div className='flex flex-col lg:px-24 px-6 lg:gap-16 gap-8 lg:py-28 py-12 h-fit'>
            <span className='flex justify-between items-center'>
                <span className='lg:text-5xl text-3xl font-bold text-[#000000]/80'>Our Services</span>
                <Link to='/our-services'>
                    <span className='flex lg:px-10 px-3 lg:py-3 py-1 rounded-[2rem] w-fit gap-2 items-center border-[#000000]/20 hover:bg-[#ee803c] transition-all duration-500 ease-in-out transform text-[#ee803c] hover:text-[#ffffff] border'>
                        <FaDotCircle className='w-4'/>
                        <span className='lg:text-xl text-lg font-light'>View all</span>
                    </span>
                </Link>
            </span>
            <span className='flex lg:flex-row flex-col lg:gap-4 gap-6 justify-center items-center'>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru8.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Upstream Oil Sector</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru7.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Wellhead Systems</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
                <span className='h-[20rem] lg:w-[25rem] w-[22rem] flex relative overflow-hidden shadow-xl rounded-2xl items-center justify-center text-[#ffffff]'>
                    <span className='bg-[url("../assets/tru9.png")] absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover hover:scale-110'></span>
                    <span className='absolute transition-all duration-1000 ease-in-out transform flex items-center justify-between w-full px-4 bottom-4'>
                        <span className='text-2xl font-medium'>Gas Industries</span>
                        <FaDotCircle className='w-4'/>
                    </span>
                </span>
            </span>
        </div>
        <div className='flex lg:flex-row flex-col-reverse lg:px-20 px-6 gap-4 lg:gap-0 justify-between items-center lg:py-12 py-6'>
            <span className='rounded-2xl bg-[#000000]/30 lg:w-[31rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru27.png")] bg-cover bg-center'></span>
            <span className='flex flex-col lg:gap-6 gap-3'>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='lg:text-4xl text-3xl font-bold tracking-wide'>Enviroment, Health & Safety</span>
                </span>
                <span className='text-[#000000]/60 lg:text-xl text-lg font-light lg:w-[42rem]'>Our dedication to safety extends beyond our immediate operations; it is interwoven with our commitment to sustainable practices and environmental stewardship. By fostering a culture of safety and fostering community engagement, we not only fulfill our obligations as responsible corporate citizens but also contribute positively to the well-being of the environments we operate within.</span>
                <span className='text-[#000000]/60 lg:text-xl text-lg font-light lg:w-[42rem]'>We hold safety as a paramount value, conscientiously safeguarding the well-being of our workforce and the communities in which we operate. In our unwavering pursuit of safety, we establish and uphold collaborative relationships with our stakeholders, nurturing open lines of communication and partnerships that ensure the alignment of safety protocols with the collective interests of all concerned parties.</span>
            </span>
        </div>
        <Footer />
    </div>
  )
}

export default About;