import React from 'react';
import Header from '../components/Header';
import Banner from '../assets/tru31.png';
import { FaDotCircle, FaPhone, FaEnvelope } from 'react-icons/fa';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <div>
        <div className='flex flex-col relative w-screen h-[35rem]'>
            <span className="absolute w-full bg-[#180d06] h-full"><img className='w-full h-full opacity-50' src={Banner} alt='' ></img></span>
            <span className='z-20'><Header /></span>
            <span className='flex flex-col z-10 lg:px-28 px-6 gap-6 lg:mt-20 mt-28'>
                <span className='flex lg:px-6 px-3 py-1 bg-[#ffffff]/30 items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='lg:text-3xl text-2xl font-semibold text-[#ffffff] tracking-wider'>Get in Touch</span>
                </span>
                <span className='flex flex-col gap-1 text-[#ffffff]'>
                    <span className='lg:text-6xl text-4xl font-semibold tracking-wide'>Contact us</span>
                    
                </span>

            </span>
        </div>
        <div className='flex flex-col lg:py-32 py-24 justify-center items-center gap-10 relative bg-[#f4ede9]'>
        <span className='flex flex-col gap-4 items-center z-10'>
            <span className='lg:text-3xl tex-xl font-semibold text-[#DD8A03]'>Get in Touch with Us</span>
            <span className='text-lg text-[#000000]/70 text-center px-4'>Let's Create Progress Together For Great Business</span>
        </span>
        <span className='flex items-center z-10'>
            <span className='flex flex-col items-center lg:px-14 px-4 '>
            <FaEnvelope  className='lg:w-20 w-16 lg:h-20 h-16 text-[#DD8A03]'/>
                <span className='flex flex-col text-center'>
                    {/* <span className='text-xl font-semibold text-[#DD8A03]'>Email</span> */}
                    <span className='text-[#000000]/70 lg:text-lg font-semibold mt-3'>admin@truenergynig.com</span>
                </span>
            </span>
            <span className='flex flex-col items-center lg:px-14 px-4  border-l-2 border-[#000000]/70'>
                <FaPhone  className='lg:w-20 w-16 lg:h-20 h-16 text-[#DD8A03]'/>
                <span className='flex flex-col text-center'>
                    {/* <span className='text-xl font-semibold text-[#DD8A03]'>Phone</span> */}
                    <span className='text-[#000000]/70 lg:text-lg font-semibold mt-6'>+234 805 337 4469</span>
                    <span className='text-[#000000]/70 lg:text-lg font-semibold'>+234 803 825 6817</span>
                    
                </span>
            </span>
        </span>
        </div>
        <Footer />
    </div>
  )
}

export default Contact;